
  import { gsap } from 'gsap'
  import { Component, Vue } from 'vue-property-decorator'
  import DragIcon from '@/components/atoms/DragIcon.vue'

  @Component({
    components: {
      DragIcon,
    },
  })
  export default class Tutorial extends Vue {
    active = false

    confirm($event: MouseEvent) {
      if (!this.active) return
      this.$emit('confirm', $event)
    }

    enter($el: HTMLElement, done: () => void) {
      const $action = $el.querySelector('h3 span')
      const $message = $el.querySelector('p span')
      const $icon = $el.querySelector('.drag-icon')

      gsap.delayedCall(2, () => (this.active = true))

      gsap
        .timeline({ delay: 1, onComplete: done })
        .fromTo($icon, { opacity: 0 }, { opacity: 1, duration: 1.6, ease: 'power2.out' }, '<+.2')
        .fromTo(
          [$action, $message],
          { y: 60, skewY: 10, opacity: 0, transformOrigin: 'left top' },
          {
            y: 0,
            skewY: 0,
            opacity: 1,
            transformOrigin: 'left top',
            duration: 0.8,
            stagger: { amount: 0.3 },
            ease: 'power2.out',
          },
          '<+.1'
        )
    }

    leave($el: HTMLElement, done: () => void) {
      gsap.timeline({ onComplete: done }).to($el, { opacity: 0, duration: 1, ease: 'power2.out' })
    }
  }
