import { Component, Vue } from 'vue-property-decorator'
import { Scenes } from '@/constants'

interface GTMEvent {
  eventCategory: string
  eventAction?: string
  eventLabel?: string
}

const dataLayer = (<any>window)['dataLayer']

export const GTMEventCategory = {
  COOKIE_PRIVACY: 'Cookie & Privacy',
  PAGEVIEW: 'Scan the Qr code',
  QUICK_MENU: 'Interactive Button',
  MOOD_SELECT: 'Clicks on Mood_Quiz',
  MOOD_QUICK_SELECT: 'Clicks on Bottle_Quick Access',
  SHOP_FLORA: 'Clicks on "Shop Now" Flora',
  START_EXPLORING: 'Clicks on "Start Exploring"',
  TOGGL_AUDIO: 'Audio',
  WELCOME: 'Clicks on Host_Welcome',
}

export const GTMEventAction = {
  AUDIO_ON: 'on',
  AUDIO_OFF: 'off',
  BACK: 'Keep Exploring',
  COOKIE: 'Clicks on Cookies',
  DOWNLOAD_PATTERN: 'Download Pattern',
  DOWNLOAD_STICKERS: 'Download Stickers',
  PLAY: 'Clicks on "Play"',
  PRIVACY: 'Clicks on Privacy Policy',
  QUICK_QUIZ: 'Clicks on Mood_Button',
  QUICK_TIPS: 'Clicks on Host_Button',
  SHOP: 'Clicks on "Shop Now"',
  VTO: 'Clicks on "Try now" for VTO',
}

export const GTMScenes = {
  [Scenes.GARDEN]: 'Gardenia_Happiness',
  [Scenes.BEDROOM]: 'Jasmine_Serenity',
  [Scenes.BEACH]: 'Magnolia_Inner_Streght',
}

export const GTMBottles = {
  [Scenes.GARDEN]: 'Gardenia Bottle',
  [Scenes.BEDROOM]: 'Jasmine Bottle',
  [Scenes.BEACH]: 'Magnolia Bottle',
}

export const GTMHost = {
  [Scenes.GARDEN]: 'Gardenia_Dog',
  [Scenes.BEDROOM]: 'Jasmine_Squirrel',
  [Scenes.BEACH]: 'Magnolia_Cat',
}

export const eventPush = (event: GTMEvent) => {
  dataLayer && dataLayer.push({ ...event, event: 'generic_event' })
  // console.log({ ...event, event: 'generic_event' })
}

@Component
export class TrackingProvider extends Vue {
  render() {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        GTMEventCategory,
        GTMEventAction,
        GTMBottles,
        GTMScenes,
        GTMHost,
        eventPush,
      })
    )
  }
}
