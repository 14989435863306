
  import { gsap } from 'gsap'
  import { Component, Vue } from 'vue-property-decorator'
  import { SCREENSAVER_IDLE_DURATION } from '@/constants'
  import Heading from '@/components/atoms/Heading.vue'

  @Component({
    components: {
      Heading,
    },
  })
  export default class ScreenSaver extends Vue {
    idleTimeout: any
    idleTimeoutExpired = false
    idleTimeoutLimit = SCREENSAVER_IDLE_DURATION * 60 * 1000 // minuti in millisecondi

    countdownDuration = 5
    countdown = { value: this.countdownDuration }

    resetIdleTimeoutEvents = [
      'mousedown',
      'mousemove',
      'touchstart',
      'touchmove',
      'keypress',
      'scroll',
      'wheel',
      'resize',
    ]

    get progress() {
      return 1 - this.countdown.value / this.countdownDuration
    }

    get progressText() {
      const progress = Math.ceil(this.countdown.value)
      return Math.max(progress, 1) // progress
    }

    get needsCountdown() {
      return this.idleTimeoutExpired
    }

    enter($el: HTMLElement, done: () => void) {
      gsap
        .timeline({
          onComplete: () => {
            done()
            this.startCountdown()
          },
        })
        .fromTo($el, { opacity: 0 }, { opacity: 1, duration: 1, ease: 'power2.out' })
    }

    startCountdown() {
      gsap
        .timeline({ onComplete: () => this.reload() })
        .fromTo(
          this.countdown,
          { value: this.countdownDuration },
          { value: 0, duration: this.countdownDuration, ease: 'linear' }
        )
    }

    resetIdleTimeout() {
      if (this.idleTimeout) clearTimeout(this.idleTimeout)
      this.idleTimeout = setTimeout(() => {
        this.idleTimeoutExpired = true
      }, this.idleTimeoutLimit)
    }

    reload() {
      location.reload()
    }

    addEventListeners() {
      this.resetIdleTimeoutEvents.forEach((event) =>
        document.addEventListener(event, this.resetIdleTimeout, this.$fn.passive())
      )
    }

    removeEventListeners() {
      this.resetIdleTimeoutEvents.forEach((event) => document.removeEventListener(event, this.resetIdleTimeout))
    }

    mounted() {
      console.log('ScreenSaver mounted')
      this.addEventListeners()
      this.resetIdleTimeout()
    }

    beforeDestroy() {
      this.removeEventListeners()
    }
  }
