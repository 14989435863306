import { enhancedIncludes, query } from './fn'
import { system, graphics, mobile, detect, dpr } from './device'

type fn = (...args: any[]) => any

class GPU {
  gpu!: string

  BLACKLIST!: boolean

  T0!: boolean
  T1!: boolean
  T2!: boolean
  T3!: boolean
  T4!: boolean
  T5!: boolean

  MT0!: boolean
  MT1!: boolean
  MT2!: boolean
  MT3!: boolean
  MT4!: boolean

  OVERSIZED!: boolean

  M_TIER!: number
  TIER!: number

  detect!: fn
  detectAll!: fn
  matchGPU!: fn
  mobileLT!: fn
  mobileGT!: fn
  mobileEq!: fn
  ready!: fn
  lt!: fn
  gt!: fn
  eq!: fn

  initialized = false

  constructor() {
    const _split: any = {}

    for (const key in ((this.detect = (match) => {
      if (graphics.gpu) return graphics.gpu.detect(match)
    }),
    (this.detectAll = () => {
      if (graphics.gpu) {
        // eslint-disable-next-line
        for (var match = true, i = 0; i < arguments.length; i++)
          // eslint-disable-next-line
          graphics.gpu.detect(arguments[i]) || (match = false)
        return match
      }
    }),
    (this.matchGPU = (str, min, max = 99999) => {
      const num = ((string: string) => {
        if (_split[string]) return _split[string]
        if (!this.detect(string)) return -1
        try {
          const num = Number(this.gpu.split(string)[1].split(' ')[0])
          return (_split[string] = num), num
        } catch (e) {
          return -1
        }
      })(str)
      return num >= min && num < max
    }),
    (this.gpu = graphics.gpu ? graphics.gpu.identifier : ''),
    'ios' == system.os && 'apple gpu' == this.gpu && new iOSGPUTest(),
    (this.BLACKLIST = GPUBlacklist.match()),
    (this.T0 = !(
      mobile ||
      (!this.BLACKLIST &&
        !this.detect('radeon(tm) r5') &&
        !this.detect('hd graphics family') &&
        !this.matchGPU('hd graphics ', 1e3, 5001) &&
        !(this.matchGPU('hd graphics ', 0, 618) && dpr > 1) &&
        !(this.detect(['hd graphics', 'iris']) && Math.max(window.innerWidth, window.innerHeight) > 1800) &&
        'intel iris opengl engine' !== this.gpu.toLowerCase() &&
        !this.matchGPU('iris(tm) graphics ', 1e3))
    )),
    (this.T1 = !(
      this.BLACKLIST ||
      mobile ||
      this.T0 ||
      (!this.matchGPU('iris(tm) graphics ', 540, 1e3) &&
        !this.matchGPU('hd graphics ', 514, 1e3) &&
        this.detect(['nvidia', 'amd', 'radeon', 'geforce']))
    )),
    (this.T2 =
      !this.BLACKLIST && !mobile && !(!this.detect(['nvidia', 'amd', 'radeon', 'geforce']) || this.T1 || this.T0)),
    (this.T3 = !(
      this.BLACKLIST ||
      mobile ||
      (!this.detect(['titan', 'amd radeon pro', 'quadro', 'radeon(tm) graphics']) &&
        !this.matchGPU('gtx ', 940) &&
        !this.matchGPU('radeon (tm) rx ', 400) &&
        !this.matchGPU('radeon rx ', 400) &&
        !this.matchGPU('radeon pro ', 420))
    )),
    (this.T4 = !(
      this.BLACKLIST ||
      mobile ||
      (!this.detect(['titan', 'quadro', 'radeon vii', 'vega']) &&
        !this.matchGPU('gtx ', 1040) &&
        !this.matchGPU('rtx') &&
        !this.matchGPU('radeon rx ', 500))
    )),
    (this.T5 = !(
      this.BLACKLIST ||
      mobile ||
      (!this.detect(['titan', 'radeon vii']) &&
        !this.matchGPU('gtx ', 1080) &&
        !this.matchGPU('rtx ', 2060) &&
        !this.matchGPU('radeon rx ', 5500))
    )),
    (this.MT0 =
      !!mobile &&
      (!!this.BLACKLIST ||
        !('ios' != system.os || !this.detect('a7')) ||
        !('android' != system.os || !this.detect('sgx')) ||
        (this.detect('adreno')
          ? this.matchGPU('adreno (tm) ', 0, 415)
          : !!this.detect('mali') && this.matchGPU('mali-t', 0, 628)))),
    (this.MT1 = !(
      !mobile ||
      this.BLACKLIST ||
      (('ios' != system.os || !this.detect(['a8', 'a9'])) && ('android' != system.os || this.MT0))
    )),
    (this.MT2 = (() => {
      if (!mobile) return false
      if (this.BLACKLIST) return false
      if ('ios' == system.os && this.detect('a10')) return true
      if (this.detect('nvidia tegra') && detect('pixel c')) return true
      if (this.detect('mali-g')) return this.matchGPU('mali-g', 73)
      if (this.detect('adreno')) {
        if (this.matchGPU('adreno (tm) ', 600, 616)) return true
        if (this.matchGPU('adreno (tm) ', 420)) return true
      }
      return !!this.detect('mali-g')
    })()),
    (this.MT3 =
      !!mobile &&
      !this.BLACKLIST &&
      (!('ios' != system.os || !this.detect(['a11', 'a12'])) ||
        !!this.matchGPU('adreno (tm) ', 530, 600) ||
        (this.detect('mali-g')
          ? this.matchGPU('mali-g', 71)
          : !(!enhancedIncludes(navigator.platform.toLowerCase(), ['mac', 'windows']) || 'chrome' != system.browser)))),
    (this.MT4 =
      !!mobile &&
      !this.BLACKLIST &&
      (!('ios' != system.os || !this.detect(['a13', 'a14', 'a15', 'a16', 'a17', 'a18'])) ||
        (this.detect('adreno')
          ? this.matchGPU('adreno (tm) ', 630)
          : !(!enhancedIncludes(navigator.platform.toLowerCase(), ['mac', 'windows']) || 'chrome' != system.browser)))),
    (this.lt = (num: number) => {
      return this.TIER > -1 && this.TIER <= num
    }),
    (this.gt = (num: number) => {
      return this.TIER > -1 && this.TIER >= num
    }),
    (this.eq = (num: number) => {
      return this.TIER > -1 && this.TIER == num
    }),
    (this.mobileEq = (num: number) => {
      return this.M_TIER > -1 && this.M_TIER == num
    }),
    (this.mobileLT = (num: number) => {
      return this.M_TIER > -1 && this.M_TIER <= num
    }),
    (this.mobileGT = (num: number) => {
      return this.M_TIER > -1 && this.M_TIER >= num
    }),
    this))
      'T' == key.charAt(0) && true === (<any>this)[key] && (this.TIER = Number(key.charAt(1))),
        'MT' == key.slice(0, 2) && true === (<any>this)[key] && (this.M_TIER = Number(key.charAt(2)))
    false !== query('gpu') &&
      (mobile || enhancedIncludes(query('gpu').toString(), 'm')
        ? ((this.TIER = -1), (this.M_TIER = Number(query('gpu').slice(1))))
        : (this.TIER = Number(query('gpu')))),
      (this.OVERSIZED = !mobile && this.TIER < 2 && Math.max(window.innerWidth, window.innerHeight) > 1500),
      'ie' == system.browser && (this.OVERSIZED = true),
      (this.initialized = true)
  }
}

class iOSGPUTest {
  constructor() {
    const test = (): number => {
      const results = []
      function getPrime() {
        return (function largest_prime_factor(n) {
          return factors(n).filter(primep).pop()
        })(1e11)
      }
      function factors(n: number) {
        let i
        const out = [],
          sqrt_n = Math.sqrt(n)
        for (i = 2; i <= sqrt_n; i++) n % i == 0 && out.push(i)
        return out
      }
      function primep(n: number) {
        return 0 === factors(n).length
      }
      for (let i = 0; i < 3; i++) {
        const time = performance.now()
        getPrime(), results.push(10 * (performance.now() - time))
      }
      return results.sort((a, b) => a - b), results[0]
    }

    const res = Math.min(screen.width, screen.height) + 'x' + Math.max(screen.width, screen.height),
      time = test()
    switch (res) {
      case '320x480':
        graphics.webgl.gpu = 'legacy'
        break
      case '320x568':
        graphics.webgl.gpu = time <= 400 ? 'apple a8' : time <= 500 ? 'apple a7' : 'legacy'
        break
      case '375x812':
      case '414x896':
        graphics.webgl.gpu = time <= 160 ? 'apple a13' : time <= 180 ? 'apple a12' : 'apple a11'
        break
      default:
      case '414x736':
      case '375x667':
      case '768x1024':
        graphics.webgl.gpu =
          time <= 160
            ? 'apple a13'
            : time <= 180
            ? 'apple a12'
            : time <= 220
            ? 'apple a11'
            : time <= 250
            ? 'apple a10'
            : time <= 360
            ? 'apple a9'
            : time <= 400
            ? 'apple a8'
            : time <= 600
            ? 'apple a7'
            : 'legacy'
        break
      case '834x1112':
        graphics.webgl.gpu =
          time <= 160 ? 'apple a13' : time <= 180 ? 'apple a12' : time <= 220 ? 'apple a11' : 'apple a10'
        break
      case '834x1194':
        graphics.webgl.gpu = 'apple a12'
        break
      case '1024x1366':
        graphics.webgl.gpu =
          time <= 160
            ? 'apple a13'
            : time <= 180
            ? 'apple a12'
            : time <= 220
            ? 'apple a11'
            : time <= 250
            ? 'apple a10'
            : 'apple a9'
    }
  }
}

class GPUBlacklist {
  static match() {
    return (
      !graphics.gpu ||
      graphics.gpu.detect([
        'radeon hd 6970m',
        'radeon hd 6770m',
        'radeon hd 6490m',
        'radeon hd 6630m',
        'radeon hd 6750m',
        'radeon hd 5750',
        'radeon hd 5670',
        'radeon hd 4850',
        'radeon hd 4870',
        'radeon hd 4670',
        'geforce 9400m',
        'geforce 320m',
        'geforce 330m',
        'geforce gt 130',
        'geforce gt 120',
        'geforce gtx 285',
        'geforce 8600',
        'geforce 9600m',
        'geforce 9400m',
        'geforce 8800 gs',
        'geforce 8800 gt',
        'quadro fx 5',
        'quadro fx 4',
        'radeon hd 2600',
        'radeon hd 2400',
        'radeon hd 2600',
        'radeon r9 200',
        'mali-4',
        'mali-3',
        'mali-2',
        'google swiftshader',
        'sgx543',
        'legacy',
        'sgx 543',
      ])
    )
  }
}

const gpu = new GPU()

export const { TIER, M_TIER } = gpu

export const convert = (tier: number) => {
  if (gpu.BLACKLIST) return 'F'
  switch (tier) {
    case 5:
      return 'A++'
    case 4:
      return 'A+'
    case 3:
      return 'A'
    case 2:
      return 'B'
    case 1:
      return 'C'
    case 0:
      return 'D'
  }
}

export const getDPR = () => {
  return gpu.OVERSIZED
    ? 0.8
    : gpu.lt(0)
    ? 1
    : gpu.lt(1)
    ? Math.min(dpr, 1.1)
    : gpu.lt(2)
    ? Math.min(dpr, 1.3)
    : gpu.lt(3)
    ? Math.min(dpr, 1.4)
    : gpu.lt(4)
    ? Math.min(dpr, 1.6)
    : gpu.lt(5)
    ? Math.min(dpr, 1.8)
    : gpu.mobileLT(0)
    ? 0.8
    : gpu.mobileLT(1)
    ? Math.min(dpr, 1)
    : gpu.mobileLT(2)
    ? Math.min(dpr, 1.1)
    : gpu.mobileLT(3)
    ? Math.min(dpr, 1.2)
    : gpu.mobileLT(4)
    ? Math.min(dpr, 1.4)
    : gpu.mobileLT(5)
    ? Math.min(dpr, 1.6)
    : 1
}

export const getWT = () => {
  return mobile ? 0 : gpu.lt(3) ? 128 : 256 //  gpu.OVERSIZED || gpu.lt(2) || gpu.mobileLT(2) ? 0 : gpu.lt(3) || gpu.mobileLT(3) ? 128 : 256
}

export const getPP = () => {
  return graphics._webglContext.webgl2 && !mobile && gpu.gt(1)
}

export const getFPS = () => {
  return gpu.lt(0) || gpu.mobileLT(0) ? 30 : 60
}
