import { Vector3, Vector2 } from 'three'

export const SET_MOOD = 'setMood'
export const SET_TIPS = 'setTips'
export const SET_SCENE = 'setScene'
export const SET_ROUTER = 'setRouter'
export const SET_LOCALE = 'setLocale'
export const SET_PORTAL = 'setPortal'
export const SET_FETCHED = 'setFetched'
export const SET_HOTSPOT = 'setHotspot'
export const SET_STARTED = 'setStarted'
export const SET_WELCOME = 'setWelcome'
export const SET_SETTINGS = 'setSettings'
export const SET_PROGRESS = 'setProgress'
export const SET_COMPLETE = 'setComplete'
export const SET_TUTORIAL = 'setTutorial'
export const SET_RETAILERS = 'setRetailers'
export const SET_COLLECTION = 'setCollection'

export type RetailerState = {
  [key: string]: {
    retailer: string
    qrcode: string
    shop?: string
    redirect?: string
  }
}

export type CollectionState = {
  [key: string]: {
    hotspots: HotspotState[]
  }
}

export type SettingState = {
  [key: string]: any
}

export type RouterState = {
  to: { [key: string]: any }
  from: { [key: string]: any }
}

export type MediaState = {
  source: string
  width: number
  height: number
}

export type QrCodeState = {
  poster: MediaState
  qrcode: MediaState
}

export type MoodTheme = {
  theme: string
  primary: string
  light: string
  dark: string
}

export type HotspotState = {
  gtm: string
  uid: string
  uids: string
  type: string
  link: string
  action: string
  product: string
  position: Vector3
  projection: Vector2
  iframe: MediaState
  hiddenOnFragranceVariant: boolean // hiddenOnChina: boolean
  media: MediaState | MediaState[] | QrCodeState
}

export type AppState = {
  collection: CollectionState
  retailers: RetailerState
  settings: SettingState
  hotspot: HotspotState | null
  router: RouterState
  progress: number
  complete: boolean
  tutorial: boolean
  welcome: boolean
  welcomes: any
  fetched: boolean
  started: boolean
  scenes: string[]
  portal: boolean
  locale: boolean
  scene: string
  mood: string
  tips: boolean
}
