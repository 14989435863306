import { settings } from '@/services/config'
import { isDefaultMode } from '@/services/mode'
import { AppState } from './types'
import { AppMoods, Scenes } from '@/constants'

export default <AppState>{
  collection: {},
  retailers: {},
  progress: 0,
  settings,
  complete: false,
  tutorial: false,
  welcome: false,
  welcomes: {
    [Scenes.GARDEN]: false,
    [Scenes.BEDROOM]: false,
    [Scenes.BEACH]: false,
  },
  fetched: false,
  started: false,
  hotspot: null,
  portal: false,
  locale: false,
  tips: false, // tips toggle
  mood: AppMoods.HAPPINESS,
  scene: isDefaultMode ? Scenes.QUIZ : Scenes.QUIZ,
  scenes: isDefaultMode
    ? [Scenes.QUIZ, Scenes.GARDEN, Scenes.BEDROOM, Scenes.BEACH]
    : [Scenes.QUIZ, Scenes.GARDEN, Scenes.BEDROOM, Scenes.BEACH],

  router: {
    to: {},
    from: {},
  },
}
