
  import { MoodThemes, Scenes } from '@/constants'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import Heading from '@/components/atoms/Heading.vue'
  import Spritesheet from '@/components/atoms/Spritesheet.vue'
  import Swiper, { Pagination, Navigation } from 'swiper'
  import Close from '@/components/atoms/Close.vue'
  import * as cache from '@/services/cache'
  import gsap from 'gsap'

  Swiper.use([Pagination, Navigation])

  @Component({
    components: {
      Close,
      Heading,
      Spritesheet,
    },
  })
  export default class Tips extends Vue {
    @Prop()
    scene!: string

    @Prop()
    mood!: string

    swiper!: Swiper

    $refs!: {
      panel: HTMLElement
      swiper: HTMLElement
      pagination: HTMLElement
      navigationNext: HTMLElement
      navigationPrev: HTMLElement
    }

    get theme() {
      return MoodThemes[this.mood].theme
    }

    get tipsArray() {
      return Array.from({ length: 1 }, (_, i) => i)
    }

    get needsSwiper() {
      return this.tipsArray.length > 1
    }

    get spriteConfig() {
      return Scenes.GARDEN === this.scene
        ? {
            sprite: cache.get(`garden-cache`)[`garden-host-2`],
            fps: 16,
            speed: 0.8,
            frames: 21,
            cols: 5,
            rows: 5,
            width: 400,
            height: 400,
          }
        : Scenes.BEDROOM === this.scene
        ? {
            sprite: cache.get(`bedroom-cache`)[`bedroom-host-2`],
            fps: 16,
            speed: 0.8,
            frames: 21,
            cols: 5,
            rows: 5,
            width: 400,
            height: 400,
          }
        : {
            sprite: cache.get(`beach-cache`)[`beach-host-2`],
            fps: 16,
            speed: 0.8,
            frames: 30,
            cols: 6,
            rows: 5,
            width: 400,
            height: 400,
          }
    }

    enter($el: HTMLElement, done: () => void) {
      const $panel = $el.querySelector('.panel')
      const $close = $el.querySelector('.close')

      gsap
        .timeline({ onComplete: done })
        .fromTo($el, { opacity: 0 }, { opacity: 1, duration: 0.8, ease: 'power2.inOut' }, '<')
        .fromTo($panel, { y: 0, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8, ease: 'power2.out' }, '<+.1')
        .fromTo(
          $close,
          { scale: 0.5, rotation: 180, opacity: 0 },
          { scale: 1, rotation: 0, opacity: 1, duration: 0.6, ease: 'expo.out' },
          '<+.2'
        )
    }

    leave($el: HTMLElement, done: () => void) {
      const $close = $el.querySelector('.close')

      gsap
        .timeline({ onComplete: done })
        .to($close, { scale: 0.5, rotation: 180, opacity: 0, duration: 0.6, ease: 'expo.inOut' })
        .to($el, { opacity: 0, duration: 0.6, ease: 'power2.out' }, '<+.1')
    }

    mounted() {
      const { swiper, pagination, navigationNext, navigationPrev } = this.$refs

      this.needsSwiper &&
        (this.swiper = new Swiper(swiper, {
          loop: true,
          pagination: {
            el: pagination,
            clickable: true,
          },
          navigation: {
            nextEl: navigationNext,
            prevEl: navigationPrev,
          },
        }))
    }

    async destroyed() {
      if (!this.swiper) return
      await this.$timer.defer(1)
      this.swiper.destroy(true, false)
    }
  }
