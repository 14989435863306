
  import { gsap } from 'gsap'
  import { Getter } from 'vuex-class'
  import { isWechatMode } from '@/services/mode'
  import { REVEAL_DURATION, Scenes } from '@/constants'
  import { HotspotState, RetailerState } from '@/store/types'
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import i18n, { fetchI18nLocale, availableLanguages } from '@/i18n'
  import SoundIcon from '@/components/atoms/SoundIcon.vue'
  import QuickMenu from '@/components/atoms/QuickMenu.vue'
  import Selector from '@/components/atoms/Selector.vue'
  import LinkBtn from '@/components/atoms/LinkBtn.vue'
  import Logo from '@/components/atoms/Logo.vue'

  @Component({
    components: {
      QuickMenu,
      SoundIcon,
      Selector,
      LinkBtn,
      Logo,
    },
  })
  export default class Header extends Vue {
    @Getter('native')
    native!: boolean

    @Getter('scenes')
    scenes!: string[]

    @Getter('scene')
    currentScene!: string

    @Getter('product')
    currentProduct!: string

    @Getter('shoplink')
    shoplink!: (key: string) => string

    @Getter('retailer')
    retailer!: RetailerState

    @Getter('tips')
    tips!: boolean

    @Prop()
    hotspot!: HotspotState

    @Prop()
    quizOverlayActive!: boolean

    @Prop()
    needsQuizAsOverlay!: boolean

    @Prop()
    quickMenuActive!: boolean

    @Prop()
    active!: boolean

    @Prop()
    muted!: boolean

    @Prop()
    theme!: string

    @Prop()
    mood!: string

    @Prop()
    mode!: string

    needsTipsMessages = true
    needsQuizMessages = true

    needsTipsBadge = false
    needsQuizBadge = false

    get currentLocale() {
      return i18n.locale
    }

    get languages() {
      const array = [...availableLanguages]
      array.pop()
      return array
    }

    get needsLanguageSelector() {
      return !(this.native || isWechatMode)
    }

    get needsShopLink() {
      return this.shoplink(this.currentProduct) !== undefined && !this.quizOverlayActive && this.active
    }

    get needsQuickMenu() {
      return !(this.currentScene === Scenes.QUIZ || this.tips) && this.active
    }

    async updateLocale(locale: string) {
      //console.log({ locale })
      await fetchI18nLocale(locale)
    }

    @Watch('currentScene')
    onCurrentSceneChange() {
      if (!this.needsQuizAsOverlay) {
        this.needsTipsMessages = true
        this.needsQuizMessages = true
        this.needsTipsBadge = false
        this.needsQuizBadge = false
      }
    }

    openQuiz() {
      this.needsQuizBadge = false
      this.$emit('quiz', this.currentScene)
    }

    openTips() {
      this.needsTipsBadge = false
      this.$emit('tips')
    }

    tipsDone() {
      this.needsTipsMessages = false
      this.needsTipsBadge = true
    }

    quizDone() {
      this.needsQuizMessages = false
      this.needsQuizBadge = true
    }

    shoplinkEnter($el: HTMLElement, done: () => void) {
      gsap.fromTo($el, { opacity: 0 }, { opacity: 1, duration: 0.8, delay: 1, ease: 'power2.inOut', onComplete: done })
    }

    shoplinkLeave($el: HTMLElement, done: () => void) {
      gsap.to($el, { opacity: 0, duration: 0.8, ease: 'power2.inOut', onComplete: done })
    }

    enter($el: HTMLElement, done: () => void) {
      const $brand = $el.querySelectorAll('.logo .logo__brand path')
      const $name = $el.querySelectorAll('.logo .logo__name path')
      const $locale = $el.querySelector('.language-selector')
      const $sound = $el.querySelector('.sound-icon')
      const $link = $el.querySelector('.link-btn')

      gsap
        .timeline({ delay: REVEAL_DURATION, onComplete: done })
        .add(
          gsap
            .timeline()
            .fromTo(
              [$el, $locale, $link, $sound],
              { opacity: 0 },
              { opacity: 1, duration: 0.6, stagger: 0.12, ease: 'power2.inOut' }
            ),
          '<'
        )
        .add(
          gsap
            .timeline()
            .fromTo(
              $brand,
              { opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` },
              {
                opacity: 1,
                filter: `blur(${0}px)`,
                webkitFilter: `blur(${0}px)`,
                duration: 0.6,
                stagger: { amount: 0.8 },
                ease: 'power2.inOut',
              },
              '<'
            )
            .fromTo(
              $name,
              { opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` },
              {
                opacity: 1,
                filter: `blur(${0}px)`,
                webkitFilter: `blur(${0}px)`,
                duration: 0.6,
                stagger: { amount: 0.8 },
                ease: 'power2.inOut',
              },
              '<+.1'
            ),
          '<'
        )
    }
  }
