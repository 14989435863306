
  import { Component, Prop } from 'vue-property-decorator'
  import Heading from '@/components/atoms/Heading.vue'
  import Swiper, { Pagination } from 'swiper'
  import MediaWidget from './MediaWidget'

  Swiper.use([Pagination])

  @Component({
    components: {
      Heading,
    },
  })
  export default class ImageGallery extends MediaWidget {
    @Prop()
    theme!: string

    swiper!: Swiper

    $refs!: {
      swiper: HTMLElement
      pagination: HTMLElement
    }

    mounted() {
      const { swiper, pagination } = this.$refs

      if (this.media.length > 1) {
        this.swiper = new Swiper(swiper, {
          loop: true,
          spaceBetween: 160,
          pagination: {
            el: pagination,
            clickable: true,
          },
        })
      }
    }

    async destroyed() {
      if (this.swiper) {
        await this.$timer.defer(1)
        this.swiper.destroy(true, false)
      }
    }
  }
