
  import { gsap } from 'gsap'
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class Spritesheet extends Vue {
    @Prop()
    sprite!: HTMLImageElement

    @Prop()
    frames!: number

    @Prop()
    cols!: number

    @Prop()
    rows!: number

    @Prop()
    width!: number

    @Prop()
    height!: number

    @Prop()
    speed!: number

    @Prop()
    fps!: number

    context!: CanvasRenderingContext2D

    spriteImage!: HTMLImageElement

    timeline!: gsap.core.Tween

    $el!: HTMLCanvasElement

    spriteWidth = 0
    spriteHeight = 0

    currentRow = 0
    currentCol = 0
    currentFrame = 0

    frameCountPrev = -1
    frameCount = { value: 0 }
    frameDuration = 1000 / this.fps
    previousFrameTime = performance.now()

    draw() {
      this.context.clearRect(0, 0, this.width, this.height)

      this.context.drawImage(
        this.spriteImage,
        this.currentCol * this.spriteWidth,
        this.currentRow * this.spriteHeight,
        this.spriteWidth,
        this.spriteHeight,
        0,
        0,
        this.width,
        this.height
      )

      this.currentCol = this.currentFrame % this.cols
      this.currentRow = Math.floor(this.currentFrame / this.cols)

      this.currentFrame++
      if (this.currentFrame >= this.frames) {
        this.currentFrame = 0
      }

      /* this.currentCol++
      console.log(this.currentCol * this.currentRow)
      if (this.currentCol >= this.cols) {
        this.currentCol = 0
        this.currentRow++
        if (this.currentRow >= this.rows) {
          this.currentRow = 0
        }
      } */
    }

    /* tick() {
      const deltaTime = performance.now() - this.previousFrameTime
      if (deltaTime >= this.frameDuration) {
        this.draw()
        this.previousFrameTime = performance.now()
      }
    } */

    play() {
      this.timeline = gsap
        .fromTo(
          this.frameCount,
          { value: 0 },
          {
            value: 20 * 10,
            ease: 'steps(20)',
            duration: 1,
            repeat: -1,
            onUpdate: () => {
              const currentValue = this.frameCount.value / 10
              if (currentValue !== this.frameCountPrev) {
                this.draw()
                this.frameCountPrev = currentValue
              }
            },
          }
        )
        .timeScale(this.speed)
    }

    stop() {
      if (this.timeline) {
        this.timeline.kill()
      }
    }

    setup() {
      this.$el.width = this.width
      this.$el.height = this.height

      this.context = this.$el.getContext('2d')!

      this.spriteImage = this.sprite
      this.spriteWidth = this.spriteImage.width / this.cols
      this.spriteHeight = this.spriteWidth // this.spriteImage.height / this.rows
    }

    mounted() {
      this.setup()
      this.play()
    }

    beforeDestroy() {
      this.stop()
      gsap.delayedCall(0.6, () => {
        this.context.clearRect(0, 0, this.width, this.height)
        this.context = null!
      })
    }
  }
