
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class Selector extends Vue {
    @Prop()
    options!: string[]

    @Prop()
    current!: string

    @Prop()
    theme!: string

    @Prop()
    name!: string

    @Prop()
    mode!: string

    @Prop()
    dir!: string

    selected = ''

    focus = false

    $refs!: {
      selection: HTMLElement
    }

    get isVariant() {
      return false
    }

    get label() {
      const current = this.options.indexOf(this.current)

      return 'scenes' === this.name
        ? this.$t(`header_${this.name}${this.isVariant ? '_variant' : ''}[${current}]`)
        : 'languages' === this.name
        ? //? availableLanguagesLabels[current]
          this.$t(`header_languages_abbreviations[${current}]`)
        : this.current
    }

    getOptionLabelByIndex(index: number) {
      return this.$t(`header_${this.name}${this.isVariant ? '_variant' : ''}[${index}]`)
    }

    select() {
      this.focus = !this.focus
    }

    clear() {
      this.focus = false
    }

    mounted() {
      document.body.addEventListener('click', this.clear)
    }

    destroyed() {
      document.body.removeEventListener('click', this.clear)
    }
  }
