
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { MoodThemes, REVEAL_DURATION, Scenes } from '@/constants'
  import Swiper, { Mousewheel, Scrollbar } from 'swiper'
  import Spritesheet from '@/components/atoms/Spritesheet.vue'
  import Heading from '@/components/atoms/Heading.vue'
  import Button from '@/components/atoms/Button.vue'
  import * as cache from '@/services/cache'
  import gsap from 'gsap'

  Swiper.use([Mousewheel, Scrollbar])

  @Component({
    components: {
      Spritesheet,
      Heading,
      Button,
    },
  })
  export default class HostWelcome extends Vue {
    @Prop()
    scene!: string

    @Prop()
    mood!: string

    scroller!: Swiper

    $refs!: {
      panel: HTMLElement
      scroller: HTMLElement
      scrollbar: HTMLElement
    }

    get theme() {
      return MoodThemes[this.mood].theme
    }

    get spriteConfig() {
      return Scenes.GARDEN === this.scene
        ? {
            sprite: cache.get(`garden-cache`)[`garden-host-1`],
            fps: 16,
            speed: 0.8,
            frames: 20,
            cols: 5,
            rows: 4,
            width: 400,
            height: 400,
          }
        : Scenes.BEDROOM === this.scene
        ? {
            sprite: cache.get(`bedroom-cache`)[`bedroom-host-1`],
            fps: 16,
            speed: 0.8,
            frames: 40,
            cols: 7,
            rows: 6,
            width: 400,
            height: 400,
          }
        : {
            sprite: cache.get(`beach-cache`)[`beach-host-1`],
            fps: 16,
            speed: 0.8,
            frames: 30,
            cols: 7,
            rows: 6,
            width: 400,
            height: 400,
          }
    }

    enter($el: HTMLElement, done: () => void) {
      const $panel = $el.querySelector('.panel')

      gsap
        .timeline({ delay: REVEAL_DURATION, onComplete: done })
        .fromTo($el, { opacity: 0 }, { opacity: 1, duration: 0.8, ease: 'power2.inOut' }, '<')
        .fromTo($panel, { y: 0, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8, ease: 'power2.out' }, '<+.1')
    }

    leave($el: HTMLElement, done: () => void) {
      gsap.timeline({ onComplete: done }).to($el, { opacity: 0, duration: 0.6, ease: 'power2.out' })
    }

    mounted() {
      const { scroller, scrollbar } = this.$refs
      const { mobile } = this.$device

      this.scroller = new Swiper(scroller, {
        direction: 'vertical',
        slidesPerView: 'auto',
        allowTouchMove: mobile,
        mousewheel: !mobile,
        freeMode: true,
        scrollbar: {
          el: scrollbar,
        },
      })
    }

    destroyed() {
      this.scroller.destroy(true, false)
    }
  }
