//import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import {
  LoadingManager,
  TextureLoader,
  ImageLoader,
  sRGBEncoding,
  RepeatWrapping,
  EquirectangularReflectionMapping,
  HalfFloatType,
  AudioLoader,
  LinearFilter,
} from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Scenes } from '@/constants'
import * as cache from '@/services/cache'
import { mobile } from '@/vue/modules/device'
import store from '@/store'

const { BASE_URL: base } = process.env

const envBase = mobile ? `${base}gltf/_mobile` : `${base}gltf`
const mapBase = mobile ? `${base}img/textures/maps/_mobile` : `${base}img/textures/maps`
const spritesBase = mobile ? `${base}img/textures/sprites/_mobile` : `${base}img/textures/sprites`

const manager = new LoadingManager()

//const dracoLoader = new DRACOLoader(manager)
//dracoLoader.setDecoderPath(`${base}draco`)

const gltfLoader = new GLTFLoader(manager)
//gltfLoader.setDRACOLoader(dracoLoader)

const textureLoader = new TextureLoader(manager)

const imageLoader = new ImageLoader(manager)

const audioLoader = new AudioLoader(manager)

const fetchSharedAssets = () => {
  const sharedCache = `shared-cache`

  cache.add(sharedCache, {})

  // textures
  ;[{ key: 'map-env', source: `${base}img/textures/maps/env-map.jpg` }].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.mapping = EquirectangularReflectionMapping
      texture.encoding = sRGBEncoding
      cache.get(sharedCache)[key] = texture
    })
  })
  ;[
    { key: 'map-leaf', source: `${base}img/textures/maps/leaf-map.png` },
    { key: 'map-flower-1', source: `${base}img/textures/flowers/01.png` },
    { key: 'map-flower-2', source: `${base}img/textures/flowers/02.png` },
    { key: 'map-flower-3', source: `${base}img/textures/flowers/03.png` },
    { key: 'map-flower-4', source: `${base}img/textures/flowers/04.png` },
    { key: 'map-flower-5', source: `${base}img/textures/flowers/05.png` },
    { key: 'map-flower-6', source: `${base}img/textures/flowers/06.png` },
    { key: 'map-flower-7', source: `${base}img/textures/flowers/07.png` },
    { key: 'map-flower-8', source: `${base}img/textures/flowers/08.png` },
    { key: 'map-flower-9', source: `${base}img/textures/flowers/09.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.encoding = sRGBEncoding
      cache.get(sharedCache)[key] = texture
    })
  })
  ;[
    { key: 'map-cloud-1', source: `${mapBase}/Texture_nuovola_2_alpha_Texture_nuovola_2_mod.png` },
    { key: 'map-cloud-2', source: `${mapBase}/Texture_nuovola_1_alpha_Texture_nuovola_1_mod.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sharedCache)[key] = texture
    })
  })

  // sounds
  ;[
    { key: 'sound-quiz-environment', source: `${base}audio/flora_music_quiz.mp3` },
    { key: 'sound-garden-environment', source: `${base}audio/flora_music_happy.mp3` },
    { key: 'sound-bedroom-environment', source: `${base}audio/flora_music_serenity.mp3` },
    { key: 'sound-beach-environment', source: `${base}audio/flora_music_strength.mp3` },
    { key: 'sound-transition', source: `${base}audio/transition.mp3` },
    { key: 'sound-hotspot', source: `${base}audio/tap.mp3` },
  ].map(({ key, source }: any) => {
    audioLoader.load(source, (buffer) => {
      cache.get(sharedCache)[key] = buffer
    })
  })
}

const fetchQuizSceneAssets = () => {
  const sceneCache = `${Scenes.QUIZ}-cache`

  cache.add(sceneCache, {})
  ;[
    { key: 'pink-bottle', source: `${envBase}/quiz/boccetta_rosa/boccetta_rosa.gltf` },
    { key: 'green-bottle', source: `${envBase}/quiz/boccetta_verde/boccetta_verde.gltf` },
    { key: 'purple-bottle', source: `${envBase}/quiz/boccetta_viola/boccetta_viola.gltf` },
  ].map(({ key, source }: any) => {
    gltfLoader.load(source, ({ scene }) => {
      cache.get(sceneCache)[key] = scene
    })
  })

  // texture
  ;[
    { key: 'background', source: `${envBase}/quiz/limbo.png` },
    { key: 'shadow', source: `${envBase}/quiz/ombra_bake.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.minFilter = LinearFilter
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      cache.get(sceneCache)[key] = texture
    })
  })
}

const fetchGardenSceneAssets = () => {
  const sceneCache = `${Scenes.GARDEN}-cache`

  cache.add(sceneCache, {})

  // environment
  ;[{ key: 'gltf-environment', source: `${envBase}/garden/gucci_flora_3_scene_1_wip_12.gltf` }].map(
    ({ key, source }: any) => {
      gltfLoader.load(source, ({ scene }) => {
        cache.get(sceneCache)[key] = scene.getObjectByName('Scene_1') // scene
      })
    }
  )

  // sprites
  ;[
    { key: 'sprite-garden-dog', source: `${spritesBase}/garden-dog-sprite.png` },
    { key: 'sprite-garden-red-cat', source: `${spritesBase}/red-cat-sprite.png` },
    { key: 'sprite-garden-mirror', source: `${spritesBase}/garden-mirror-sprite.png` },
    { key: 'sprite-garden-white-cat', source: `${spritesBase}/white-cat-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.minFilter = LinearFilter
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  })

  // host
  ;[
    { key: 'garden-host-1', source: `${spritesBase}/garden-host-1.png` },
    { key: 'garden-host-2', source: `${spritesBase}/garden-host-2.png` },
  ].map(({ key, source }: any) => {
    imageLoader.load(source, (image) => {
      cache.get(sceneCache)[key] = image
    })
  })
}

const fetchBedroomSceneAssets = () => {
  const sceneCache = `${Scenes.BEDROOM}-cache`

  cache.add(sceneCache, {})

  // environment
  ;[{ key: 'gltf-environment', source: `${envBase}/bedroom/gucci_flora_3_scene_2_wip_1.gltf` }].map(
    ({ key, source }: any) => {
      gltfLoader.load(source, ({ scene }) => {
        cache.get(sceneCache)[key] = scene
      })
    }
  )

  // sprites
  ;[
    { key: 'sprite-bedroom-dog-eyes', source: `${spritesBase}/dog-eyes-sprite.png` },
    { key: 'sprite-bedroom-cat', source: `${spritesBase}/cat-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.minFilter = LinearFilter
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  })

  // host
  ;[
    { key: 'bedroom-host-1', source: `${spritesBase}/bedroom-host-1.png` },
    { key: 'bedroom-host-2', source: `${spritesBase}/bedroom-host-2.png` },
  ].map(({ key, source }: any) => {
    imageLoader.load(source, (image) => {
      cache.get(sceneCache)[key] = image
    })
  })
}

const fetchBeachSceneAssets = () => {
  const sceneCache = `${Scenes.BEACH}-cache`

  cache.add(sceneCache, {})

  // environment
  ;[{ key: 'gltf-environment', source: `${envBase}/beach/gucci_flora_3_scene_3_wip_2.gltf` }].map(
    ({ key, source }: any) => {
      gltfLoader.load(source, ({ scene }) => {
        cache.get(sceneCache)[key] = scene.getObjectByName('Total_Scene') // scene
      })
    }
  )

  // sprites
  ;[
    { key: 'sprite-beach-dog-crab', source: `${spritesBase}/dog-crab-sprite.png` },
    { key: 'sprite-beach-shell', source: `${spritesBase}/shell-sprite.png` },
    { key: 'sprite-beach-crab', source: `${spritesBase}/crab-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.minFilter = LinearFilter
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  })

  // host
  ;[
    { key: 'beach-host-1', source: `${spritesBase}/beach-host-1.png` },
    { key: 'beach-host-2', source: `${spritesBase}/beach-host-2.png` },
  ].map(({ key, source }: any) => {
    imageLoader.load(source, (image) => {
      cache.get(sceneCache)[key] = image
    })
  })

  // water
  ;[
    { key: 'normal0', source: `${base}img/textures/water/Water_1_M_Normal.jpg` },
    { key: 'normal1', source: `${base}img/textures/water/Water_2_M_Normal.jpg` },
    { key: 'flow', source: `${base}img/textures/water/Water_1_M_Flow.jpg` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      cache.get(sceneCache)[key] = texture
    })
  })
}

export const fetchSceneAssets = (scene?: string) => {
  switch (scene) {
    case Scenes.QUIZ:
      fetchQuizSceneAssets()
      break
    case Scenes.GARDEN:
      fetchGardenSceneAssets()
      break
    case Scenes.BEDROOM:
      fetchBedroomSceneAssets()
      break
    case Scenes.BEACH:
      fetchBeachSceneAssets()
      break
  }

  if (!cache.get('sharedCache')) {
    fetchSharedAssets()
  }

  return new Promise<void>((resolve) => {
    manager.onProgress = (_, loaded, total) => store.dispatch('progress', loaded / total)
    manager.onLoad = () => resolve()
  })
}
