
  import { gsap } from 'gsap'
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { HotspotState } from '@/store/types'
  import { MoodThemes } from '@/constants'

  export type Message = {
    value: string
    index: number
  }

  @Component
  export default class QuickMenu extends Vue {
    messages: Message[] = []

    @Prop()
    hotspot!: HotspotState

    @Prop()
    active!: boolean

    @Prop()
    mood!: string

    @Prop()
    needsTipsMessages!: boolean

    @Prop()
    needsQuizMessages!: boolean

    @Prop()
    needsTipsBadge!: boolean

    @Prop()
    needsQuizBadge!: boolean

    intervalDelay = 3000
    timeoutDelay = 2000

    interval!: any
    timeout!: any

    limit = 0
    count = 0
    index = 0

    get theme() {
      return MoodThemes[this.mood].theme
    }

    @Watch('active')
    onActiveChange() {
      this.start()
    }

    @Watch('hotspot')
    onHotspotChange(hotspot: HotspotState) {
      if (!hotspot) {
        this.start()
      } else {
        this.stop()
      }
    }

    pushNewMessage(message: Message) {
      this.messages.splice(this.messages.length, 0, message)
    }

    removeLastMessage() {
      this.messages.splice(0, 1)
    }

    removeAllMessages() {
      this.messages.splice(0, this.messages.length)
    }

    startTipsMessages() {
      this.limit = 2
      this.count = 0
      this.interval = setInterval(() => {
        if (this.count <= this.limit - 1) {
          const message = {
            value: this.$t(`shared_chatbot_tips_message_${this.count}`).toString(),
            index: ++this.index,
          }
          this.pushNewMessage(message)
        }

        if (this.count > 0) {
          this.removeLastMessage()
        }

        if (this.count > 0 && this.messages.length === 0) {
          this.stopMessages()
          this.startQuizTimer()
          this.$emit('tips-done')
        }

        ++this.count
      }, this.intervalDelay)
    }

    startQuizMessages() {
      this.limit = 1
      this.count = 0
      this.interval = setInterval(() => {
        if (this.count <= this.limit - 1) {
          const message = {
            value: this.$t(`shared_chatbot_quiz_message_${this.count}`).toString(),
            index: ++this.index,
          }
          this.pushNewMessage(message)
        }

        if (this.count > 0) {
          this.removeLastMessage()
        }

        if (this.count > 0 && this.messages.length === 0) {
          this.stopMessages()
          this.$emit('quiz-done')
        }

        ++this.count
      }, this.intervalDelay)
    }

    stopMessages() {
      clearInterval(this.interval)
      clearTimeout(this.timeout)
    }

    startTipsTimer(delay = 2000) {
      this.timeout = setTimeout(() => {
        this.startTipsMessages()
      }, delay)
    }

    startQuizTimer(delay = 2000) {
      this.timeout = setTimeout(() => {
        this.startQuizMessages()
      }, delay)
    }

    start() {
      if (!this.active) return
      if (this.needsTipsMessages) {
        this.startTipsTimer(this.timeoutDelay)
      } else if (this.needsQuizMessages) {
        this.startQuizTimer(this.timeoutDelay)
      }
    }

    stop() {
      this.stopMessages()
      this.removeAllMessages()
    }

    enter($el: HTMLDivElement, done: () => void) {
      gsap.fromTo(
        $el,
        { scale: 0.5, opacity: 0 },
        { scale: 1, opacity: 1, duration: 0.8, delay: 0.4, ease: 'expo.out', onComplete: done }
      )
    }

    leave($el: HTMLDivElement, done: () => void) {
      gsap.to($el, { scale: 0.5, opacity: 0, duration: 0.6, ease: 'expo.inOut', onComplete: done })
    }

    mounted() {
      this.start()
    }

    destroyed() {
      this.stop()
    }
  }
