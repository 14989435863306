
  import { values } from 'lodash'
  import { Action, Getter } from 'vuex-class'
  import { HotspotState, RetailerState } from '@/store/types'
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { AppMoods, Scenes } from '@/constants'
  import {
    ModeProvider,
    StateProvider,
    ResizeProvider,
    PointerProvider,
    VisibilityProvider,
    MixerProvider,
    TrackingProvider,
  } from '@/providers'
  import ScreenSaver from '@/components/organsims/ScreenSaver.vue'
  import HostWelcome from '@/components/organsims/HostWelcome.vue'
  import Navigator from '@/components/organsims/Navigator.vue'
  import Hotspots from '@/components/organsims/Hotspots.vue'
  import Tutorial from '@/components/organsims/Tutorial.vue'
  import Welcome from '@/components/organsims/Welcome.vue'
  import Overlay from '@/components/organsims/Overlay.vue'
  import Portal from '@/components/organsims/Portal.vue'
  import Landing from '@/components/organsims/Landing.vue'
  import Header from '@/components/molecules/Header.vue'
  import Footer from '@/components/molecules/Footer.vue'
  import Loader from '@/components/organsims/Loader.vue'
  import Dolly from '@/components/organsims/Dolly.vue'
  import Tips from '@/components/organsims/Tips.vue'
  import BaseScene from '@/webgl/scenes/base/Base'

  @Component({
    components: {
      ThreeProvider: () => import(/* webpackChunkName: "webgl" */ '@/webgl/providers/Three'),
      QuizScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/quiz/Quiz.vue'),
      GardenScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/garden/Garden.vue'),
      BedroomScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/bedroom/Bedroom.vue'),
      BeachScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/beach/Beach.vue'),
      Starlight: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Starlight'),
      Sprinkles: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Sprinkles'),
      Flowers: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Flowers'),
      Lights: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Lights'),
      Glow: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Glow'),
      Canvas: () => import(/* webpackChunkName: "webgl" */ '@/webgl/Canvas.vue'),
      VisibilityProvider,
      TrackingProvider,
      PointerProvider,
      ResizeProvider,
      StateProvider,
      MixerProvider,
      ModeProvider,
      HostWelcome,
      ScreenSaver,
      Navigator,
      Hotspots,
      Tutorial,
      Welcome,
      Overlay,
      Landing,
      Portal,
      Loader,
      Header,
      Footer,
      Dolly,
      Tips,
    },
  })
  export default class Root extends Vue {
    @Getter('hotspots')
    hotspots!: HotspotState[]

    @Getter('hotspot')
    currentHotspot!: HotspotState

    @Getter('retailer')
    currentRetailer!: RetailerState

    @Getter('scene')
    currentScene!: string
    previousScene!: string
    fromFloraScene?: string

    @Getter('mood')
    currentMood!: string

    @Getter('complete')
    complete!: boolean

    @Getter('started')
    started!: boolean

    @Getter('tutorial')
    tutorial!: boolean

    @Getter('welcome')
    welcome!: boolean

    @Getter('welcomes')
    welcomes!: any

    @Getter('locale')
    locale!: boolean

    @Getter('portal')
    portal!: boolean

    @Getter('devcam')
    devcam!: boolean

    @Getter('debug')
    debug!: boolean

    @Getter('tips')
    tips!: boolean

    @Getter('scenes')
    scenes!: string[]

    @Getter('screensaver')
    screensaver!: boolean

    @Action('fetch')
    fetch!: () => void

    @Action('finish')
    finish!: () => void

    @Action('start')
    starExperience!: () => void

    @Action('gotoNextScene')
    gotoNextScene!: () => void

    @Action('setScene')
    setScene!: (scene: string) => void

    @Action('setMood')
    setMood!: (mood: string) => void

    @Action('setPortal')
    setPortal!: (portal: boolean) => void

    @Action('setTipsState')
    setTipsState!: (state: boolean) => void

    @Action('setWelcomeState')
    setWelcomeState!: (welcome: boolean) => void

    @Action('setTutorialState')
    setTutorialState!: (state: boolean) => void

    @Action('setCurrentHotspot')
    setCurrentHotspot!: (hotspot: HotspotState | null) => void

    sceneLoading = false
    quizActive = true

    // uiTheme = 'dark'

    $refs!: {
      scene: BaseScene
      loader: Loader
    }

    @Watch('currentScene', { immediate: true })
    onSceneChange(scene: string, prev: string) {
      this.sceneLoading = scene !== Scenes.QUIZ // true
      prev !== Scenes.QUIZ && (this.previousScene = prev)
      const prevMoodIndex = this.scenes.indexOf(prev) - 1
      const sceneMoodIndex = this.scenes.indexOf(scene) - 1
      const randomMoodIndex = Math.floor(Math.random() * 3)
      const moodIndex = sceneMoodIndex > -1 ? sceneMoodIndex : prevMoodIndex > -1 ? prevMoodIndex : randomMoodIndex
      this.setMood(values(AppMoods)[moodIndex])
    }

    @Watch('started')
    async onStarted() {
      await this.$timer.defer(0.8)
      this.$refs.scene.reveal()
    }

    get sceneComponent() {
      const sceneComponent = `${this.$fn.capitalize(this.currentScene)}Scene`
      return this.currentScene.length > 0 ? sceneComponent : null
    }

    get active() {
      return (
        this.currentScene !== Scenes.QUIZ &&
        !this.sceneLoading &&
        this.started &&
        this.tutorial &&
        (this.welcome || this.needsQuizAsOverlay)
      )
    }

    get needsLoader() {
      return this.complete && this.sceneLoading
    }

    get needsLanding() {
      return this.locale && !this.started
    }

    get needsWelcome() {
      return !this.sceneLoading && !this.welcome && !this.needsQuizAsOverlay && this.currentScene !== Scenes.QUIZ
    }

    get needsTutorial() {
      return (
        !this.sceneLoading &&
        !this.tutorial &&
        !this.needsQuizAsOverlay &&
        this.welcome &&
        this.currentScene !== Scenes.QUIZ
      )
    }

    get uiTheme() {
      return this.quizActive || this.sceneLoading ? 'dark' : 'light'
    }

    get needsOverlay() {
      return this.currentHotspot !== null
    }

    get needsQuiz() {
      return this.currentScene === Scenes.QUIZ && !this.sceneLoading && this.started
    }

    get needsQuizAsOverlay() {
      return (
        (this.currentScene === Scenes.QUIZ && this.previousScene !== undefined) ||
        this.currentScene === this.previousScene
      )
    }

    get needsTips() {
      return this.tips
    }

    get needsDolly() {
      return !this.quizActive
    }

    get needsStarlight() {
      return !this.quizActive
    }

    get needsScreenSaver() {
      return this.screensaver
    }

    get needsEffects() {
      return this.$gpu.getPP()
    }

    async sceneLeave(_: any, done: () => void) {
      await this.$refs.scene.unmount(done, this.needsQuizAsOverlay)
      this.quizActive = false
    }

    async sceneEnter(_: any, done: () => void) {
      await this.$refs.scene.mount(done, this.needsQuizAsOverlay, this.started)
      this.quizActive = this.currentScene === Scenes.QUIZ
      this.sceneLoading = false
      if (!this.welcomes[this.currentScene] && !this.quizActive) {
        this.setWelcomeState(false)
      }
    }

    async mounted() {
      await this.$timer.defer(2)
      await this.fetch()
      await this.$timer.defer(0.6)
      await this.finish()
    }
  }
