
  import { Component, Prop } from 'vue-property-decorator'
  import DownloadIcon from '@/components/atoms/Download.vue'
  import Heading from '@/components/atoms/Heading.vue'
  import Swiper, { Pagination } from 'swiper'
  import MediaWidget from './MediaWidget'

  Swiper.use([Pagination])

  @Component({
    components: {
      Heading,
      DownloadIcon,
    },
  })
  export default class DownloadGallery extends MediaWidget {
    @Prop()
    theme!: string

    swiper!: Swiper

    download = '#'

    uuid = null

    $refs!: {
      swiper: HTMLElement
      pagination: HTMLElement
    }

    mounted() {
      const { swiper, pagination } = this.$refs

      this.swiper = new Swiper(swiper, {
        loop: true,
        spaceBetween: 180,
        pagination: {
          el: pagination,
          clickable: true,
        },
        on: {
          slideChange: (swiper) => {
            this.download = this.media[swiper.realIndex].download
            this.uuid = this.media[swiper.realIndex].uid || null
          },
        },
      })
    }

    async destroyed() {
      await this.$timer.defer(1)
      this.swiper.destroy(true, false)
    }
  }
