
  import { Vector2 } from 'three'
  import { Getter } from 'vuex-class'
  import { HotspotState, RetailerState } from '@/store/types'
  import { WidgetActions, MediaQ, MoodThemes } from '@/constants'
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import Swiper, { Mousewheel, Scrollbar } from 'swiper'
  import DownloadGallery from '@/components/molecules/widgets/DownloadGallery.vue'
  import VideoCarousel from '@/components/molecules/widgets/VideoCarousel.vue'
  import ImageGallery from '@/components/molecules/widgets/ImageGallery.vue'
  import ImagePattern from '@/components/molecules/widgets/ImagePattern.vue'
  import ImagePoster from '@/components/molecules/widgets/ImagePoster.vue'
  import AudioPlayer from '@/components/molecules/widgets/AudioPlayer.vue'
  import MobileLink from '@/components/molecules/widgets/MobileLink.vue'
  import Button from '@/components/atoms/Button.vue'
  import gsap from 'gsap'

  Swiper.use([Mousewheel, Scrollbar])

  @Component({
    components: {
      Button,
      MobileLink,
      AudioPlayer,
      ImagePoster,
      ImagePattern,
      ImageGallery,
      VideoCarousel,
      DownloadGallery,
    },
  })
  export default class Overlay extends Vue {
    @Getter('retailer')
    retailer!: RetailerState

    @Getter('shoplink')
    shoplink!: (key: string) => string

    @Prop()
    hotspot!: HotspotState

    @Prop()
    viewport!: Vector2

    @Prop()
    visible!: boolean

    @Prop()
    muted!: boolean

    @Prop()
    scene!: string

    @Prop()
    mood!: string

    scroller!: Swiper

    $refs!: {
      panel: HTMLElement
      scroller: HTMLElement
      scrollbar: HTMLElement
    }

    get widget() {
      return this.hotspot.type
    }

    get action() {
      return this.hotspot.action
    }

    get theme() {
      return MoodThemes[this.mood].theme
    }

    get needsShopCta() {
      return WidgetActions.SHOP === this.action && this.retailer.shop !== undefined
    }

    get needsIgCta() {
      return WidgetActions.IG === this.action && this.$device.mobile
    }

    get needsVtoCta() {
      return WidgetActions.VTO === this.action
    }

    get actions() {
      return !this.needsShopCta && !this.needsVtoCta && !this.needsIgCta ? 'no-actions' : ''
    }

    @Watch('viewport', { deep: true, immediate: true })
    async resize() {
      await this.$nextTick()

      const { panel, scroller } = this.$refs
      const { innerWidth, innerHeight } = window

      const offset = innerWidth >= MediaQ.MD ? 60 : innerWidth >= MediaQ.XS ? 60 : 40

      gsap.set([panel, scroller], { maxHeight: innerHeight - offset })

      this.scroller.update()
    }

    enter($el: HTMLElement, done: () => void) {
      const $panel = $el.querySelector('.panel')

      gsap
        .timeline({ delay: 0.4, onComplete: done })
        .fromTo($el, { opacity: 0 }, { opacity: 1, duration: 0.8, ease: 'power2.inOut' }, '<')
        .fromTo($panel, { y: 0, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8, ease: 'power2.out' }, '<+.1')
    }

    leave($el: HTMLElement, done: () => void) {
      gsap.timeline({ onComplete: done }).to($el, { opacity: 0, duration: 0.6, ease: 'power2.out' })
    }

    mounted() {
      const { scroller, scrollbar } = this.$refs
      const { mobile } = this.$device

      this.scroller = new Swiper(scroller, {
        direction: 'vertical',
        slidesPerView: 'auto',
        allowTouchMove: mobile,
        mousewheel: !mobile,
        freeMode: true,
        scrollbar: {
          el: scrollbar,
        },
      })
    }

    destroyed() {
      this.scroller.destroy(true, false)
    }
  }
