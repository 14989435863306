import { render, staticRenderFns } from "./Selector.vue?vue&type=template&id=13ba2848&scoped=true&"
import script from "./Selector.vue?vue&type=script&lang=ts&"
export * from "./Selector.vue?vue&type=script&lang=ts&"
import style0 from "./Selector.vue?vue&type=style&index=0&id=13ba2848&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ba2848",
  null
  
)

export default component.exports