import Vue from 'vue'
import axios from 'axios'
import { fetchLocale } from '@/services/locales'
import { isWechatMode } from '@/services/mode'
import VueI18n, { LocaleMessageObject } from 'vue-i18n'
import availableLanguagesISO from '@/i18n-iso.json'

Vue.use(VueI18n)

const loadedLanguages: string[] = []

export const availableLanguages = [...availableLanguagesISO.map((locale) => locale.split('.')[0]), 'zh']

/* export const availableLanguages: string[] = [
  'ar', // Arabic
  'en', // English
  'fr', // French
  'de', // German
  'id', // Indonesia Bahasa
  'it', // Italian
  'ja', // Japanese
  'ko', // Korean
  'pt', // Portuguese
  'br', // Brazilian
  'ru', // Russian
  'cn', // Chinese (Simplified)
  'tw', // Chinese (Traditional)
  'es', // Spanish
  'th', // Thai
  'vi', // Vietnamese
  'zh', // Chinese (App)
] */

/* export const availableLanguagesLabels: string[] = [
  'العربية', // Arabic
  'en', // English
  'fr', // French
  'de', // German
  'id', // Indonesia Bahasa
  'it', // Italian
  'ja', // Japanese
  '한국어', // Korean
  'pt', // Portuguese
  'PT (Brasil)', // Brazilian
  'ru', // Russian
  '简体中文', // Chinese (Simplified)
  '繁體中文', // Chinese (Traditional)
  'es', // Spanish
  'th', // Thai
  'vi', // Vietnamese
  'zh', // Chinese (App)
] */

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
})

const detectLanguage = () => {
  const language =
    (navigator.languages !== undefined ? navigator.languages[0] : navigator.language) ||
    (navigator as any).browserLanguage
  const languageSplit = language.trim().toLowerCase().split(/-|_/)
  const browserLanguage = languageSplit[0] === languageSplit[1] ? languageSplit[0] : languageSplit[1]
  const languageSearchParam = new URLSearchParams(location.search).get('lang')
  const locale = languageSearchParam || browserLanguage
  const fallback = process.env.VUE_APP_I18N_FALLBACK_LOCALE
  return availableLanguages.includes(locale) ? locale : fallback
}

const setI18nLanguage = (lang: string): string => {
  i18n.locale = lang
  const dir = 'ar' === lang ? 'rtl' : 'ltr'
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  document.querySelector('html')?.setAttribute('dir', dir)
  return lang
}

export const fetchI18nLocale = async (locale?: string) => {
  const lang = isWechatMode ? i18n.locale : locale || detectLanguage()

  if (loadedLanguages.includes(lang)) return Promise.resolve(setI18nLanguage(lang))

  const response = await fetchLocale(lang)
  const messages = response.data as LocaleMessageObject
  i18n.setLocaleMessage(lang, messages)
  loadedLanguages.push(lang)

  return setI18nLanguage(lang)
}

export default i18n
