
  import { Vector2 } from 'three'
  import { HotspotState } from '@/store/types'
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import Heading from '@/components/atoms/Heading.vue'
  import Swiper, { Mousewheel, Scrollbar } from 'swiper'
  import gsap from 'gsap'

  Swiper.use([Mousewheel, Scrollbar])

  @Component({
    components: {
      Heading,
    },
  })
  export default class Portal extends Vue {
    @Prop()
    hotspot!: HotspotState

    @Prop()
    viewport!: Vector2

    @Prop()
    scene!: string

    scroller!: Swiper

    userConsent = false

    $refs!: {
      panel: HTMLElement
      scroller: HTMLElement
      scrollbar: HTMLElement
      iframe: HTMLIFrameElement
      iframeContainer: HTMLElement
    }

    /* @Watch('userConsent')
    onUserConsent(consent: boolean) {
      if (consent) {
        this.scroller.destroy(true, false)
        this.resize()
      }
    } */

    get needsNewWindowIframe() {
      return this.$device.system.os === 'ios' && this.$device.system.browser === 'safari'
    }

    handleConsent() {
      this.userConsent = true
      this.scroller && this.scroller.destroy(true, false)
      this.resize()

      /* if (this.needsNewWindowIframe) {
        window.open(this.hotspot.iframe.source, '_blank')
        this.$emit('back')
      } else {
        this.userConsent = true
        this.scroller && this.scroller.destroy(true, false)
        this.resize()
      } */
    }

    @Watch('viewport', { deep: true, immediate: true })
    async resize() {
      await this.$nextTick()

      const { panel, scroller, iframe, iframeContainer } = this.$refs

      const selector = gsap.utils.selector(document)
      const bbox = selector('.overlay .panel')[0].getBoundingClientRect()

      const panelWidth = bbox.width
      const panelHeight = this.$device.mobile ? bbox.height : 620

      const width = this.$device.mobile ? panelWidth - 20 : 420
      const height = panelHeight - 20

      iframe && gsap.set(iframe, { width, height })
      iframeContainer && gsap.set(iframeContainer, { width, height, marginTop: 10 })

      panel && gsap.set(panel, { width: panelWidth, height: panelHeight })
      scroller && gsap.set(scroller, { maxHeight: panelHeight })

      this.scroller.update()

      // console.log('requestIframeSize', { width, height })
    }

    receiveMessage(event: any) {
      if (event.data.type === 'iframeSize') {
        const { width, height } = event.data.message
        this.adjustIframeSize(width, height)
      } else if (event.data.type === 'cameraFailed') {
        // console.log('cameraFailed')
      } else if (event.data.type === 'closed') {
        // console.log('closed')
        this.$emit('back')
      } else if (event.data.eventCategory === 'Flora Looks Clicks') {
        // console.log('Flora Looks Clicks:', event.data)
        // this.$emit('update', event.data)
      }
    }

    adjustIframeSize(width: string, height: string) {
      const { iframe, iframeContainer } = this.$refs
      iframeContainer.style.width = width
      iframeContainer.style.height = height
      iframe.style.width = width
      iframe.style.height = height
    }

    enter($el: HTMLElement, done: () => void) {
      const $panel = $el.querySelector('.panel')

      gsap
        .timeline({ delay: 0.4, onComplete: done })
        .fromTo($el, { opacity: 0 }, { opacity: 1, duration: 0.8, ease: 'power2.inOut' }, '<')
        .fromTo($panel, { y: 0, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8, ease: 'power2.out' }, '<+.1')
    }

    leave($el: HTMLElement, done: () => void) {
      gsap.timeline({ onComplete: done }).to($el, { opacity: 0, duration: 0.6, ease: 'power2.out' })
    }

    created() {
      window.addEventListener('message', this.receiveMessage, false)
    }

    mounted() {
      const { scroller, scrollbar } = this.$refs
      const { mobile } = this.$device

      this.scroller = new Swiper(scroller, {
        direction: 'vertical',
        slidesPerView: 'auto',
        allowTouchMove: mobile,
        mousewheel: !mobile,
        freeMode: true,
        scrollbar: {
          el: scrollbar,
        },
      })
    }

    destroyed() {
      this.scroller.destroy(true, false)

      window.removeEventListener('message', this.receiveMessage)
    }
  }
