import { Component, Vue } from 'vue-property-decorator'
import { mode, isWechatMode } from '@/services/mode'

@Component
export class ModeProvider extends Vue {
  get mode() {
    return mode
  }

  get needsFooter() {
    return !isWechatMode
  }

  render() {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        mode: this.mode,
        needsFooter: this.needsFooter,
      })
    )
  }
}
