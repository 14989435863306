
  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class SoundIcon extends Vue {
    @Prop()
    active!: boolean

    @Prop()
    theme!: string
  }
