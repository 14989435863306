import { ActionTree } from 'vuex'
import { Scenes } from '@/constants'
import i18n, { fetchI18nLocale } from '@/i18n'
import { fetchWebFont } from '@/services/webfonts'
import { fetchSceneAssets } from '@/services/assets'
import { GTMEventCategory, eventPush } from '@/providers/modules/Tracking'
import { fetchRetailersData, fetchCollectionData } from '@/services/data'
import {
  AppState,
  SET_COLLECTION,
  SET_COMPLETE,
  SET_FETCHED,
  SET_HOTSPOT,
  SET_LOCALE,
  SET_PROGRESS,
  SET_RETAILERS,
  SET_ROUTER,
  SET_PORTAL,
  SET_SCENE,
  SET_SETTINGS,
  SET_STARTED,
  SET_TUTORIAL,
  SET_WELCOME,
  SET_MOOD,
  SET_TIPS,
} from './types'

export default <ActionTree<AppState, any>>{
  async fetchLocale({ commit }) {
    await fetchI18nLocale()
    await fetchWebFont(i18n.locale)
    commit(SET_LOCALE, true)
  },

  async fetchData({ commit }) {
    const { data: retailers } = await fetchRetailersData()
    const { data: collection } = await fetchCollectionData()
    commit(SET_COLLECTION, collection)
    commit(SET_RETAILERS, retailers)
  },

  async fetch({ commit, dispatch }) {
    await fetchSceneAssets(Scenes.QUIZ)
    commit(SET_FETCHED, true)
    dispatch('gtmPush')
  },

  gtmPush({ getters }) {
    if (getters['retailer']) {
      eventPush({
        eventCategory: GTMEventCategory.PAGEVIEW,
        eventAction: getters['retailer'].retailer,
      })
    }
  },

  syncRouter({ commit }, payload) {
    const { hash: _hash } = payload.to
    const hash = _hash.length ? _hash.split('?')[0] : null // Hash.DEFAULT
    commit(SET_ROUTER, {
      from: { ...payload.from },
      to: { ...payload.to, hash },
    })
  },

  gotoNextScene({ commit, state: { scenes, scene } }) {
    const { length } = scenes
    const index = scenes.indexOf(scene)
    const next = index >= length - 1 ? 0 : index + 1
    commit(SET_SCENE, scenes[next])
  },

  setCurrentHotspot({ commit }, payload) {
    commit(SET_HOTSPOT, payload)
  },

  setWelcomeState({ commit, state: { scene } }, payload) {
    commit(SET_WELCOME, { payload, scene })
  },

  setTutorialState({ commit }, payload) {
    commit(SET_TUTORIAL, payload)
  },

  setTipsState({ commit }, payload) {
    commit(SET_TIPS, payload)
  },

  syncSettings({ commit }, payload) {
    commit(SET_SETTINGS, payload)
  },

  setPortal({ commit }, payload) {
    commit(SET_PORTAL, payload)
  },

  setScene({ commit }, payload) {
    commit(SET_SCENE, payload)
  },

  setMood({ commit }, payload) {
    commit(SET_MOOD, payload)
  },

  progress({ commit }, payload) {
    commit(SET_PROGRESS, payload)
  },

  finish({ commit }) {
    commit(SET_COMPLETE, true)
  },

  start({ commit }) {
    commit(SET_STARTED, true)
  },
}
