
  import { gsap } from 'gsap'
  import { HotspotState } from '@/store/types'
  import { ENTER_DURATION, Events } from '@/constants'
  import { PerspectiveCamera, Vector2 } from 'three'
  import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class Navigator extends Vue {
    @Prop()
    hotspots!: HotspotState[]

    @Prop()
    landing!: boolean

    @Prop()
    scene!: string

    @Inject()
    camera!: PerspectiveCamera

    vector = new Vector2()

    center = new Vector2()

    threshold = 0.0012

    current = -1

    first = false

    last = false

    select(dir: number) {
      const distances = []

      const { length } = this.hotspots

      for (let i = 0; i < length; ++i) {
        const hotspot = this.hotspots[i]
        const distance = this.getDistanceFromCamera(hotspot)
        const distanceX = hotspot.position.x - this.camera.position.x - this.threshold * dir
        distanceX * dir > 0 && distances.push({ ...hotspot, distance, index: i })
      }

      const hotspot = distances.hasMin('distance') as any

      hotspot && this.$emit('snap', hotspot.position.x)
    }

    tick() {
      const distances = []
      //const threshold = this.threshold * 2

      const { length } = this.hotspots

      if (length === 0) return

      for (let i = 0; i < length; ++i) {
        const hotspot = this.hotspots[i]
        const distance = this.getDistanceFromCamera(hotspot)
        const direction = this.camera.position.x - hotspot.position.x
        distances.push({ ...hotspot, distance, direction, index: i })
      }

      const hotspot = distances.hasMin('distance') as any

      const threshold = 1 // Scenes.PORCH === this.scene ? 2 : 1

      this.first = hotspot.index === 0 // && hotspot.direction < threshold
      this.last = hotspot.index >= length - threshold // && hotspot.direction > -threshold
    }

    getDistanceFromCamera(hotspot: HotspotState): number {
      const cameraPosition = this.camera.position.clone().setY(0).setZ(0)
      const hotspotPosition = hotspot.position.clone().setY(0).setZ(0)
      return cameraPosition.distanceTo(hotspotPosition)
    }

    enter($el: HTMLElement, done: () => void) {
      const $arrows = $el.querySelectorAll('.navigator__arrow')

      const delay = this.landing ? 0.6 : ENTER_DURATION

      gsap
        .timeline({ delay, onComplete: done })
        .fromTo($arrows, { opacity: 0 }, { opacity: 1, duration: 1, ease: 'power2.out' })
    }

    leave($el: HTMLElement, done: () => void) {
      const $arrows = $el.querySelectorAll('.navigator__arrow')

      gsap.timeline({ onComplete: done }).to($arrows, { opacity: 0, duration: 1, ease: 'expo.inOut' })
    }

    mounted() {
      this.$bus.$on(Events.GL.RENDER, this.tick)
    }

    destroyed() {
      this.$bus.$off(Events.GL.RENDER, this.tick)
    }
  }
