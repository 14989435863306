import type { MoodTheme } from './store/types'

export const SCREENSAVER_IDLE_DURATION = parseFloat(process.env.VUE_APP_SCREENSAVER_IDLE_DURATION || '0')

export const WELCOME_DURATION = 3.5
export const REVEAL_DURATION = 3
export const LEAVE_DURATION = 3.8
export const ENTER_DURATION = 3

export const US_COUNTRY = (window as any).US_COUNTRY

export const MediaQ = {
  XS: 360,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
}

export const AppMode = {
  FRAGRANCE: 'fragrance',
  DEFAULT: 'default',
  WECHAT: 'wechat',
}

export const AppMoods = {
  HAPPINESS: 'happiness',
  SERENITY: 'serenity',
  STRENGHT: 'strenght',
}

export const MoodThemes: Record<string, MoodTheme> = {
  happiness: { theme: 'pink', primary: '#e7abaa', light: '#fdcdcd', dark: '#d85f5f' },
  serenity: { theme: 'green', primary: '#80d5c0', light: '#fffff0', dark: '#1d6453' },
  strenght: { theme: 'purple', primary: '#a196c4', light: '#b9b1d3', dark: '#5e4a9f' },
}

export const Hash = {
  DEFAULT: '#default',
  SEPHORA: '#sephora',
  GUCCI: '#store',
}

export const Scenes = {
  QUIZ: 'quiz',
  GARDEN: 'garden',
  BEDROOM: 'bedroom',
  BEACH: 'beach',
}

export const Events = {
  GL: {
    RENDER: 'gl:render',
    RESIZE: 'gl:resize',
    REVEAL: 'gl:reveal',
    ACTIVE: 'gl:active',
    COMPILE: 'gl:compile',
  },
  GUI: {
    CHANGE: 'gui:change',
  },
}

export const Widgets = {
  AUDIO_PLAYER: 'audio-player',
  IMAGE_POSTER: 'image-poster',
  IMAGE_GALLERY: 'image-gallery',
  IFRAME_PORTAL: 'iframe-portal',
  VIDEO_CAROUSEL: 'video-carousel',
  MOBILE_LINK: 'mobile-link',
}

export const WidgetActions = {
  NONE: 'none',
  SHOP: 'shop',
  VTO: 'vto',
  IG: 'ig',
}
